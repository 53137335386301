const base = {
  adminLogin: "/admin/login",
  adminGetInfo: "/admin/auth",
  article: {
    getlist: "/article/list",
    create: "/article",
    getDetail: "/article/",
    update: "/article/",
    delete: "/article/",
  },
  category: {
    getlist: "/category",
    create: "/category",
    getDetail: "/category/",
    update: "/category/",
    delete: "/category/",
  },
  comment: {
    getlist: "/comment/list",
    create: "/comment",
    getDetail: "/comment/",
    update: "/comment/",
    delete: "/comment/",
  },
  reply: {
    getlist: "/reply/list",
    create: "/reply",
    getDetail: "/reply/",
    update: "/reply/",
    delete: "/reply/",
  },
  user: {
    getlist: "/user/list",
    create: "/user",
    getDetail: "/user/",
    update: "/user/",
    delete: "/user/",
  },
};

export default base;
