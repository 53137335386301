<template>
  <div>
    <el-row>
      <el-col :span="4">
        <Menu></Menu>
      </el-col>
      <el-col :span="20">
        <Header></Header>
        <router-view></router-view>
      </el-col>
    </el-row>
    <Footer></Footer>
  </div>
</template>

<script>
import Menu from "../../components/Menu.vue";
import Header from "../../components/Header.vue";
import Footer from "../../components/Footer.vue";
export default {
  name: "Layout",
  components: {
    Menu,
    Header,
    Footer,
  },
};
</script>

<style></style>
