import base from "../api/base";

import request from "./index";

export const admin = {
  login(data) {
    return request({
      url: base.adminLogin,
      method: "post",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  getInfo(data) {
    return request({
      url: base.adminGetInfo,
      method: "get",
      data,
    });
  },
};

export const article = {
  list(params) {
    return request({
      url: base.article.getlist,
      methods: "get",
      params,
    });
  },
  create(data) {
    return request({
      url: base.article.create,
      methods: "post",
      data,
    });
  },
  detail(data) {
    console.log(base);
    return request({
      url: base.article.getDetail + data.id,
      method: "get",
      data,
    });
  },
  update(data) {
    return request({
      url: base.article.update + data.id,
      method: "put",
      data,
    });
  },
  delete(data) {
    return request({
      url: base.article.delete + data.id,
      method: "delete",
      data,
    });
  },
  update_status(data) {
    return request({
      url: "article/update",
      method: "put",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  get_by_keys(params) {
    return request({
      url: "article/unknown-search",
      method: "get",
      params,
    });
  },
};

export const category = {
  list(params) {
    return request({
      url: base.category.getlist,
      methods: "get",
      params,
    });
  },
  create(data) {
    return request({
      url: base.category.create,
      methods: "post",
      data,
    });
  },
  detail(data) {
    return request({
      url: base.category.getDetail + data.id,
      method: "get",
      data,
    });
  },
  update(data) {
    return request({
      url: base.category.update + data.id,
      method: "put",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  delete(data) {
    return request({
      url: base.category.delete + data.id,
      method: "delete",
      data,
    });
  },
  get_by_keys(params) {
    return request({
      url: "category/unknown-search",
      method: "get",
      params,
    });
  },
};

export const comment = {
  list(params) {
    return request({
      url: base.comment.getlist,
      methods: "get",
      params,
    });
  },
  create(data) {
    return request({
      url: base.comment.create,
      methods: "post",
      data,
    });
  },
  detail(data) {
    return request({
      url: base.comment.getDetail + data.id,
      method: "get",
      data,
    });
  },
  update(data) {
    return request({
      url: base.comment.update + data.id,
      method: "put",
      data,
    });
  },
  delete(data) {
    return request({
      url: base.comment.delete + data.id,
      method: "delete",
      data,
    });
  },
  update_status(data) {
    return request({
      url: "comment/update",
      method: "put",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  get_by_keys(params) {
    return request({
      url: "comment/unknown-search",
      method: "get",
      params,
    });
  },
};
export const reply = {
  list(params) {
    return request({
      url: base.reply.getlist,
      method: "get",
      params,
    });
  },
  create(data) {
    return request({
      url: base.reply.create,
      method: "post",
      data,
    });
  },
  detail(data) {
    return request({
      url: base.reply.getDetail + data.id,
      method: "get",
      data,
    });
  },
  update(data) {
    return request({
      url: base.reply.update + data.id,
      method: "put",
      data,
    });
  },
  delete(data) {
    return request({
      url: base.reply.delete + data.id,
      method: "delete",
      data,
    });
  },
  update_status(data) {
    return request({
      url: "reply/update",
      method: "put",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  get_by_keys(params) {
    return request({
      url: "reply/unknown-search",
      method: "get",
      params,
    });
  },
};

export const user = {
  list(params) {
    return request({
      url: base.user.getlist,
      methods: "get",
      params,
    });
  },
  create(data) {
    return request({
      url: base.user.create,
      methods: "post",
      data,
    });
  },
  detail(data) {
    return request({
      url: base.user.getDetail + data.id,
      method: "get",
      data,
    });
  },
  update(data) {
    return request({
      url: base.user.update + data.id,
      method: "put",
      data,
      headers: {
        "Content-Type": "application/x-www-form-urlencoded",
      },
    });
  },
  delete(data) {
    return request({
      url: base.user.delete + data.id,
      method: "delete",
      data,
    });
  },
  get_by_keys(params) {
    return request({
      url: "user/unknown-search",
      method: "get",
      params,
    });
  },
};

export const home = {
  userNum(params) {
    return request({
      url: "/back-stage/home/user-num",
      method: "get",
      params,
    });
  },
  articleNum(params) {
    return request({
      url: "/back-stage/home/article-num",
      method: "get",
      params,
    });
  },
  userCreateTime(params) {
    return request({
      url: "/back-stage/home/create-time/user",
      method: "get",
      params,
    });
  },
  data_board(params) {
    return request({
      url: "/back-stage/home/num",
      method: "get",
      params,
    });
  },
};
export const trend_data = {
  userCreateTime(params) {
    return request({
      url: "/back-stage/home/create-time/user",
      method: "get",
      params,
    });
  },
  articleCreateTime(params) {
    return request({
      url: "/back-stage/home/create-time/article",
      method: "get",
      params,
    });
  },
  commentCreateTime(params) {
    return request({
      url: "/back-stage/home/create-time/comment",
      method: "get",
      params,
    });
  },
  replyCreateTime(params) {
    return request({
      url: "/back-stage/home/create-time/reply",
      method: "get",
      params,
    });
  },
};
