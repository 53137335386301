<template>
  <div class="container">
    <div class="my-breadcrumb">
      <!-- <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>活动列表</el-breadcrumb-item>
        <el-breadcrumb-item>活动详情</el-breadcrumb-item>
      </el-breadcrumb> -->
      <el-breadcrumb separator="/">
        <el-breadcrumb-item :to="{ path: '/home' }">首页</el-breadcrumb-item>
        <el-breadcrumb-item
          :to="{
            path: '/' + this.$route.fullPath.split('/')[1],
          }"
          >{{
            is_home
              ? "后台首页"
              : crumb_path(this.$route.fullPath.split("/")[1])
          }}</el-breadcrumb-item
        >
        <el-breadcrumb-item v-if="!is_home">{{
          crumb_path(this.$route.fullPath.split("/")[1]) +
          second_path_name(this.$route.fullPath.split("/")[2])
        }}</el-breadcrumb-item>
      </el-breadcrumb>
    </div>
    <div class="my-avatar">
      <el-dropdown :hide-on-click="false" @command="handleCommand">
        <el-avatar
          :size="50"
          :src="adminInfo.avatar"
          shape="square"
        ></el-avatar>
        <el-dropdown-menu slot="dropdown">
          <el-dropdown-item command="nothing">{{
            adminInfo.nickname
          }}</el-dropdown-item>
          <el-dropdown-item divided command="logout">退出登录</el-dropdown-item>
        </el-dropdown-menu>
      </el-dropdown>
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  data() {
    return {};
  },
  // filters: {
  //   pathFilter(path) {
  //     const pathMap = {
  //       'article': "帖子",
  //       'comment': "评论",
  //       'reply': "恢复",
  //       'user':'用户'
  //     };
  //     return pathMap[path];
  //   },
  // },

  watch: {
    $route: {
      handler: function (val, oldVal) {
        console.log(val);
      },
      // 深度观察监听
      deep: true,
    },
  },
  computed: {
    ...mapState({
      adminInfo: (state) => state.admin.adminInfo,
    }),
    is_home() {
      if (this.$route.fullPath.indexOf("home") === -1) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    crumb_path(path) {
      const pathMap = {
        article: "帖子",
        comment: "评论",
        reply: "恢复",
        user: "用户",
        category: "分类",
      };
      return pathMap[path];
    },
    second_path_name(path) {
      return path === "trend" ? "趋势" : "管理";
    },
    handleCommand(command) {
      if (command === "logout") {
        this.$router.push({
          path: "/login",
        });
      } else {
        return;
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.container {
  background-color: rgb(172, 186, 197);
  margin: 20px 0;
  margin-top: 0;
  height: 80px;
  .my-breadcrumb {
    margin: 5px 20px;
    float: left;
    .el-breadcrumb {
      font-size: 16px;
      line-height: 70px;
    }
  }
  .my-avatar {
    float: right;
    margin: 5px 30px;
  }
}
</style>
