import Vue from "vue";
import VueRouter from "vue-router";
import Layout from "@/views/layout/Layout";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    component: Layout,
    redirect: "/home",
    children: [
      {
        path: "home",
        component: () => import("@/views/home/Home"),
      },
      {
        path: "category",
        name: "Category",
        component: () => import("@/views/category/index"),
        children: [
          {
            path: "manage",
            name: "CategoryManage",
            component: () => import("@/views/category/CategoryManage.vue"),
          },
          {
            path: "edit",
            name: "CategoryEdit",
            component: () => import("@/views/category/CategoryEdit.vue"),
          },
        ],
      },
      {
        path: "article",
        component: () => import("@/views/article/index"),
        name: "Article",
        children: [
          {
            path: "manage",
            name: "ArticleManage",
            component: () => import("@/views/article/ArticleManage.vue"),
          },
          {
            path: "trend",
            name: "ArticleTrend",
            component: () =>
              import("@/views/article/components/articleTrend.vue"),
          },
        ],
      },
      {
        path: "user",
        component: () => import("@/views/user/index"),
        name: "User",
        children: [
          {
            path: "manage",
            name: "UserManage",
            component: () => import("@/views/user/UserManage.vue"),
          },
          {
            path: "edit",
            name: "UserEdit",
            component: () => import("@/views/user/UserEdit.vue"),
          },
          {
            path: "trend",
            name: "UserTrend",
            component: () => import("@/views/user/components/userTrend.vue"),
          },
        ],
      },
      {
        path: "comment",
        component: () => import("@/views/comment/index.vue"),
        name: "Comment",
        children: [
          {
            path: "manage",
            name: "CommentManage",
            component: () => import("@/views/comment/CommentManage"),
          },
          {
            path: "trend",
            name: "CommentTrend",
            component: () =>
              import("@/views/comment/components/commentTrend.vue"),
          },
        ],
      },
      {
        path: "reply",
        component: () => import("@/views/reply/index.vue"),
        name: "Reply",

        children: [
          {
            path: "manage",
            name: "ReplyManage",
            component: () => import("@/views/reply/replyManage.vue"),
          },
          {
            path: "trend",
            name: "ReplyTrend",
            component: () => import("@/views/reply/components/replyTrend.vue"),
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    name: "Login",
    component: () => import("@/views/login/Login.vue"),
  },
  {
    path: "/test",
    name: "Test",
    component: () => import("@/views/test/Test.vue"),
  },
];

const router = new VueRouter({
  mode: "history",
  routes,
});

export default router;
