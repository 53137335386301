import { admin } from "@/api/request";

const state = () => ({
  adminInfo: null,
  isLoginStatus: false,
});

// getters
const getters = {};

// actions
const actions = {
  async adminLogin({ state, commit }, data = {}) {
    const res = await admin.login(data);
    const admin_info = res.data.data;
    commit("set_adminInfo", {
      id: admin_info.id,
      nickname: admin_info.nickname,
      email: admin_info.email,
      avatar: "http://img.gulihanjiang.top/upload/oI1mcmYuXd.jpg",
    });
    commit("set_login_status", true);
    return res;
  },
};

// mutations
const mutations = {
  set_adminInfo(state, data) {
    sessionStorage.setItem("adminInfo", JSON.stringify(data));
    state.adminInfo = data;
  },
  set_login_status(state, data) {
    state.isLoginStatus = data;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
