<template>
  <div id="app">
    <router-view></router-view>
  </div>
</template>

<script>
import { mapState } from "vuex";
export default {
  name: "App",
  watch: {
    $route(val, OldVal) {
      // console.log(val, OldVal);
      if (sessionStorage.getItem("adminInfo")) {
        let admin_info = JSON.parse(sessionStorage.getItem("adminInfo"));
        this.$store.commit("admin/set_adminInfo", {
          id: admin_info.id,
          nickname: admin_info.nickname,
          email: admin_info.email,
          avatar: "http://img.gulihanjiang.top/upload/oI1mcmYuXd.jpg",
        });
        this.$store.commit("admin/set_login_status", true);
      }
      if (val.path === "/login") {
        return;
      }
      if (!this.isLoginStatus) {
        this.$alert("请先完成登录", "提示", {
          confirmButtonText: "确定",
        });
        setTimeout(() => {
          this.$router.push("/login");
        }, 2000);
      }
    },
  },
  computed: {
    ...mapState({
      isLoginStatus: (state) => state.admin.isLoginStatus,
    }),
  },
};
</script>

<style></style>
