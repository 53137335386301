<template>
  <el-menu
    :default-active="$route.path"
    class="el-menu-vertical-demo"
    @open="handleOpen"
    @close="handleClose"
    router
  >
    <el-menu-item index="/">
      <i class="el-icon-menu"></i>
      <span slot="title">首页</span>
    </el-menu-item>
    <el-submenu index="/category">
      <template slot="title">
        <i class="el-icon-document-copy"></i> 分类</template
      >
      <el-menu-item index="/category/manage">分类管理</el-menu-item>
    </el-submenu>
    <el-submenu index="/article">
      <template slot="title"> <i class="el-icon-tickets"></i> 帖子</template>
      <el-menu-item index="/article/trend">帖子趋势分析</el-menu-item>
      <el-menu-item index="/article/manage">帖子管理</el-menu-item>
    </el-submenu>
    <el-submenu index="/comment">
      <template slot="title"> <i class="el-icon-s-comment"></i> 评论</template>
      <el-menu-item index="/comment/trend">评论趋势分析</el-menu-item>
      <el-menu-item index="/comment/manage">评论管理</el-menu-item>
    </el-submenu>
    <el-submenu index="/reply">
      <template slot="title">
        <i class="el-icon-s-promotion"></i> 回复</template
      >
      <el-menu-item index="/reply/trend">回复趋势分析</el-menu-item>
      <el-menu-item index="/reply/manage">回复管理</el-menu-item>
    </el-submenu>
    <el-submenu index="/user">
      <template slot="title"> <i class="el-icon-user"></i>用户</template>
      <el-menu-item index="/user/trend">用户趋势分析</el-menu-item>
      <el-menu-item index="/user/manage">用户管理</el-menu-item>
    </el-submenu>
  </el-menu>
</template>

<script>
export default {
  name: "Menu",
  methods: {
    handleOpen(key, keyPath) {
      return;
      // console.log(this.$route);
      // this.$router.push(key);
      // console.log(key, keyPath);
    },
    handleClose(key, keyPath) {
      return;
      // console.log(key, keyPath);
    },
  },
};
</script>

<style lang="scss" scoped>
// .el-menu-vertical-demo {
//   // color: rgb(191, 203, 217);
//   // background-color: rgb(48, 65, 86);
// }
</style>
